import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, CardHeader, Divider, FormControlLabel, InputAdornment, Switch, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import './Calculatrice.css';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons/faAnglesRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@mui/material/IconButton';
import { useEffect } from 'react';

import { DataGrid } from '@mui/x-data-grid';

export default function Calculatrice() {

    const columns = [
        { field: 'adjudication', headerName: 'adjudication', type: 'number', width: 130 },
        { field: 'totalAuctions', headerName: 'coût total enchères', type: 'number', width: 150 },
        { field: 'totalFees', headerName: 'total frais', type: 'number', width: 130 },
        { field: 'totalCostWithoutTax', headerName: 'coût total', type: 'number', width: 130 },
        { field: 'salePrice', headerName: 'prix de revente', type: 'number', width: 130 },
        { field: 'fiscalValueAddedTax', headerName: 'plus-value brute', type: 'number', width: 130 },
        { field: 'valueTaxes', headerName: 'impots', type: 'number', width: 130 },
        { field: 'netValueAddedTax', headerName: 'plus-value nette', type: 'number', width: 130 },
        { field: 'netMargin', headerName: 'marge nette (%)', type: 'number', width: 130 },
    ];

    const [values, setValues] = useState({
        adjudication: 100000,
        courtierFees: 0,
        advancePaymentFees: 2000,
        margin: 30,
        preliminaryFees: 6000,
        lawyerFees: 2000,
        isMDB: false,
        contribution: 12000,
        financingFees: 1000,
        landTaxes: 800,
        copropFees: 800,
        works: 0,
        nopInsurance: 250,
        courtierTax: 1,
        loanTax: 4.45,
        minimumSale: 146000,
        maximumSale: 159000,
        taxOnValue: 36.2,
        proportionalRights: 0,
        mutationRight: 0,
        publicationFees: 0,
        totalAuctions: 0,
        totalFees: 0,
        valueTaxes: 0,
        totalCostWithoutTax: 0,
        fiscalValueAddedTax: 0,
        netValueAddedTax: 0,
        grossMargin: 0,
        netMargin: 0,
        objectiveNettValueAddedTax: 20000,
        objectiveNetMargin: 20,
        tableRows: []
    });


    const handleCheckChange = (event) => {
        setValues((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.checked,
        }));
    }

    const handleChange = (e) => {
        const { value, name } = e.target;
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };


    const ifChangeComputeForm = () => {
        // check if at least one value has changed

        calculateEncheres();


    }
    useEffect(() => {
        const timeOutId = setTimeout(() => ifChangeComputeForm(), 500);
        return () => clearTimeout(timeOutId);
    }, [values]);

    const initializeFormData = function () {
        const formData = new FormData();
        const formValues = {
            adjudication: values.adjudication,
            courtierFees: values.courtierFees,
            advancePaymentFees: values.advancePaymentFees,
            margin: values.margin,
            preliminaryFees: values.preliminaryFees,
            lawyerFees: values.lawyerFees,
            isMDB: values.isMDB,
            contribution: values.contribution,
            financingFees: values.financingFees,
            landTaxes: values.landTaxes,
            copropFees: values.copropFees,
            works: values.works,
            nopInsurance: values.nopInsurance,
            courtierTax: values.courtierTax,
            loanTax: values.loanTax,
            minimumSale: values.minimumSale,
            maximumSale: values.maximumSale,
            taxOnValue: values.taxOnValue,
            proportionalRights: values.proportionalRights,
            mutationRight: values.mutationRight,
            publicationFees: values.publicationFees,
            totalAuctions: values.totalAuctions,
            totalFees: values.totalFees,
            valueTaxes: values.valueTaxes,
            totalCostWithoutTax: values.totalCostWithoutTax,
            fiscalValueAddedTax: values.fiscalValueAddedTax,
            netValueAddedTax: values.netValueAddedTax,
            grossMargin: values.grossMargin,
            netMargin: values.netMargin,
            objectiveNettValueAddedTax: values.objectiveNettValueAddedTax,
            objectiveNetMargin: values.objectiveNetMargin,
        };
        for (const [key, value] of Object.entries(formValues)) {
            formData.append(camelToSnakeCase(key), value);
        }
        return formData;
    }

    function camelToSnakeCase(str) {
        return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = initializeFormData();
            const response = await axios.post(
                process.env.REACT_APP_ENCHERESIMMO_BACKEND_URL + '/api/calcul-adjudication-max',
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            );

            const { data } = response;

            setValues((prevValues) => ({
                ...prevValues,
                tableRows: data.map((row) => {
                    return {
                        ...row
                    }
                })
            }))



        } catch (error) {
            console.error(error);
        }
    }

    const calculateEncheres = async () => {
        try {
            const formData = initializeFormData();

            const response = await axios.post(
                process.env.REACT_APP_ENCHERESIMMO_BACKEND_URL + '/api/encheres-calcul',
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            );

            const { data } = response;

            const valueInCamelCase = (key) => {
                const value = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
                console.debug(value.charAt(0).toLowerCase() + value.slice(1))
                return value.charAt(0).toLowerCase() + value.slice(1);
            };

            // the response contains all values. Only set the ones that have changed
            setValues((prevValues) => {
                const updatedValues = Object.fromEntries(Object.entries(data).map(([key, value]) => {
                    return [valueInCamelCase(key), value];
                }));

                const changedValues = Object.fromEntries(Object.entries(updatedValues).filter(([key, value]) => {
                    if (Array.isArray(prevValues[key]) && Array.isArray(value)) {
                        return JSON.stringify(prevValues[key]) !== JSON.stringify(value);
                    } else {
                        return prevValues[key] !== value;
                    }
                }));

                if (Object.keys(changedValues).length === 0) {
                    return prevValues;
                }

                return {
                    ...prevValues,
                    ...changedValues
                };
            });

        } catch (error) {
            console.error(error);
        }
    };

    const handleComputeCourtierFees = async () => {
        try {
            const formData = initializeFormData();
            const response = await axios.post(
                process.env.REACT_APP_ENCHERESIMMO_BACKEND_URL + '/api/courtier-fees-calcul',
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            );
            const { data } = response;

            const valueInCamelCase = (key) => {
                const value = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
                console.debug(value.charAt(0).toLowerCase() + value.slice(1))
                return value.charAt(0).toLowerCase() + value.slice(1);
            };

            setValues((prevValues) => ({
                ...prevValues,
                ...Object.fromEntries(Object.entries(data).map(([key, value]) => [valueInCamelCase(key), value])),
            }));
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid container spacing={1} xs={12} md={4}>
                    <Grid xs={12}>
                        <Card
                            variant="outlined"
                            sx={{ borderRadius: 5, backgroundColor: '#FFFFEE', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
                        >
                            <CardHeader
                                title="Paramètres"
                                sx={{ pb: 0, borderBottom: '1px solid #ccc', textAlign: 'center' }}
                            />
                            <CardContent
                                sx={{ marginTop: 0, paddingX: 3, paddingY: 1 }}
                            >
                                <Grid container spacing={2}>
                                    <Grid xs={12}>
                                        <FormControlLabel

                                            control={<Switch
                                                name="isMDB"
                                                checked={values.isMDB}
                                                onChange={handleCheckChange}
                                            />
                                            }
                                            label="Marchand de biens"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid xs={6}>
                                        <TextField
                                            required
                                            name="objectiveNetMargin"
                                            id="objectif-marge-nette"
                                            label="Objectif de marge nette"
                                            type="number"
                                            size="small"
                                            onChange={handleChange}
                                            value={values.objectiveNetMargin}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        %
                                                    </InputAdornment>)
                                            }}
                                        />
                                    </Grid>
                                    <Grid xs={6}>
                                        <TextField
                                            required
                                            name="objectiveNettValueAddedTax"
                                            id="objectif-plus-value-nette"
                                            label="Objectif de plus-value nette avant impôt"
                                            type="number"
                                            size="small"
                                            onChange={handleChange}
                                            value={values.objectiveNettValueAddedTax}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        €
                                                    </InputAdornment>)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid xs={12}>
                        <Card variant="outlined"
                            sx={{ borderRadius: 5, backgroundColor: '#FFFFEE', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
                        >
                            <CardHeader
                                title="Adjudication"
                                sx={{ pb: 0, borderBottom: '1px solid #ccc', textAlign: 'center' }}
                            />
                            <CardContent
                                sx={{ marginTop: 0, padding: 3 }}
                            >
                                <Grid container spacing={1}>
                                    <Grid xs={6}>
                                        <TextField
                                            required
                                            name="adjudication"
                                            id="montant-adjudication"
                                            label="Montant de l'adjudication"
                                            type="number"
                                            size="small"
                                            onChange={handleChange}
                                            value={values.adjudication}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        €
                                                    </InputAdornment>)
                                            }}
                                        />
                                    </Grid>

                                    <Grid xs={6}>
                                        <TextField
                                            disabled
                                            id="droits-proportionnels"
                                            name="proportionnalRights"
                                            label="Droits proportionnels"
                                            type="number"
                                            size="small"
                                            value={values.proportionalRights}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        €
                                                    </InputAdornment>)
                                            }}
                                        />
                                    </Grid>

                                    <Grid xs={6}>
                                        <TextField
                                            required
                                            id="frais-prealables"
                                            name="preliminaryFees"
                                            label="Frais préalables"
                                            type="number"
                                            size="small"
                                            onChange={handleChange}
                                            value={values.preliminaryFees}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        €
                                                    </InputAdornment>)
                                            }}
                                        />
                                    </Grid>
                                    <Grid xs={6}>
                                        <TextField
                                            disabled
                                            id="droit-mutation"
                                            name="mutationRight"
                                            label="Droits de mutation"
                                            type="number"
                                            size="small"
                                            value={values.mutationRight}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        €
                                                    </InputAdornment>)
                                            }}
                                        />
                                    </Grid>

                                    <Grid xs={6}>
                                        <TextField
                                            required
                                            id="honoraires-avocat"
                                            name="lawyerFees"
                                            label="Honoraires d'avocat"
                                            type="number"
                                            size="small"
                                            onChange={handleChange}
                                            value={values.lawyerFees}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        €
                                                    </InputAdornment>)
                                            }}
                                        />
                                    </Grid>

                                    <Grid xs={6}>
                                        <TextField
                                            disabled
                                            id="frais-publicite"
                                            name="publicationFees"
                                            label="Frais de publicité"
                                            type="number"
                                            size="small"
                                            value={values.publicationFees}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        €
                                                    </InputAdornment>)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                            </CardActions>
                        </Card>
                    </Grid>

                </Grid>
                <Grid xs={12} md={4}>
                    <Card
                        variant="outlined"
                        sx={{ borderRadius: 5, backgroundColor: '#FFFFEE', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
                    >
                        <CardHeader
                            title="Achat-revente"
                            sx={{ pb: 0, borderBottom: '1px solid #ccc', textAlign: 'center' }}
                        />
                        <Divider />
                        <CardContent
                            sx={{ marginTop: 0, padding: 3 }}
                        >
                            <Grid container spacing={1}>
                                <Grid xs={12}>
                                    <Typography
                                        gutterBottom
                                        variant="body2"
                                        sx={{ textAlign: 'left', backgroundColor: '#B2DFDB' }}
                                    >
                                        Prix de revente
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <TextField
                                        required
                                        id="revente-min"
                                        name="minimumSale"
                                        label="Prix de revente minimum"
                                        type="number"
                                        size="small"
                                        onChange={handleChange}
                                        value={values.minimumSale}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    €
                                                </InputAdornment>)
                                        }}
                                    />
                                </Grid>

                                <Grid xs={6}>
                                    <TextField
                                        required
                                        id="revente-max"
                                        name="maximumSale"
                                        label="Prix de revente maximum"
                                        type="number"
                                        size="small"
                                        onChange={handleChange}
                                        value={values.maximumSale}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    €
                                                </InputAdornment>)
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid xs={12}>
                                    <Typography gutterBottom variant="body2"

                                        sx={{ textAlign: 'left', backgroundColor: '#B2DFDB' }}
                                    >
                                        Emprunt
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <TextField
                                        id="apport"
                                        name="contribution"
                                        label="Apport"
                                        type="number"
                                        size="small"
                                        onChange={handleChange}
                                        value={values.contribution}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    €
                                                </InputAdornment>)
                                        }}
                                    />
                                </Grid>

                                <Grid xs={6}>
                                    <TextField
                                        id="taux-emprunt"
                                        name="loanTax"
                                        label="Taux du prêt"
                                        type="number"
                                        size="small"
                                        onChange={handleChange}
                                        value={values.loanTax}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    %
                                                </InputAdornment>)
                                        }}
                                    />
                                </Grid>

                                <Grid xs={5}>
                                    <TextField
                                        id="taux-prime-courtier"
                                        name="courtierTax"
                                        label="Taux prime de courtage"
                                        type="number"
                                        size="small"
                                        onChange={handleChange}
                                        value={values.courtierTax}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    %
                                                </InputAdornment>)
                                        }}
                                    />
                                </Grid>
                                <Grid xs={2} sx={{ textAlign: 'center' }}>
                                    <IconButton aria-label="compute" onClick={handleComputeCourtierFees}><FontAwesomeIcon icon={faAnglesRight} /></IconButton>
                                </Grid>
                                <Grid xs={5}>
                                    <TextField
                                        id="frais-courtier"
                                        name="courtierFees"
                                        label="Frais de courtage"
                                        type="number"
                                        size="small"
                                        onChange={handleChange}
                                        value={values.courtierFees}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    €
                                                </InputAdornment>)
                                        }}
                                    />
                                </Grid>

                                <Grid xs={6}>
                                    <TextField
                                        id="frais-financement"
                                        name="financingFees"
                                        label="Frais de financement (dossier)"
                                        type="number"
                                        size="small"
                                        onChange={handleChange}
                                        value={values.financingFees}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    €
                                                </InputAdornment>)
                                        }}
                                    />
                                </Grid>

                                <Grid xs={6}>
                                    <TextField
                                        id="frais-remboursement-anticipe"
                                        name="advancePaymentFees"
                                        label="Frais de remboursement anticipé"
                                        type="number"
                                        size="small"
                                        onChange={handleChange}
                                        value={values.advancePaymentFees}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    €
                                                </InputAdornment>)
                                        }}
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid xs={12}>
                                    <Typography gutterBottom variant="body2"

                                        sx={{ textAlign: 'left', backgroundColor: '#B2DFDB' }}
                                    >
                                        Frais divers liés au logement
                                    </Typography>
                                </Grid>
                                <Grid xs={6}>
                                    <TextField
                                        id="charges-copro"
                                        name="copropFees"
                                        label="Charges de copropriété"
                                        type="number"
                                        size="small"
                                        onChange={handleChange}
                                        value={values.copropFees}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    €
                                                </InputAdornment>)
                                        }}
                                    />
                                </Grid>

                                <Grid xs={6}>
                                    <TextField
                                        id="taxe-fonciere"
                                        name="landTaxes"
                                        label="Taxe foncière"
                                        type="number"
                                        size="small"
                                        onChange={handleChange}
                                        value={values.landTaxes}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    €
                                                </InputAdornment>)
                                        }}
                                    />
                                </Grid>

                                <Grid xs={6}>
                                    <TextField
                                        id="assurance-pno"
                                        name="nopInsurance"
                                        label="Assurance propriétaire non occupant"
                                        type="number"
                                        size="small"
                                        onChange={handleChange}
                                        value={values.nopInsurance}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    €
                                                </InputAdornment>)
                                        }}
                                    />
                                </Grid>
                                <Grid xs={6}>
                                    <TextField
                                        id="travaux"
                                        name="works"
                                        label="Travaux"
                                        type="number"
                                        size="small"
                                        onChange={handleChange}
                                        value={values.works}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    €
                                                </InputAdornment>)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid xs={12} md={4}>
                    <Card variant="outlined"
                        sx={{ borderRadius: 5, backgroundColor: '#FFFFEE', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}
                    >
                        <CardHeader
                            title="Totaux"
                            sx={{ pb: 0, borderBottom: '1px solid #ccc', textAlign: 'center' }}
                        />
                        <CardContent
                            sx={{ marginTop: 0, padding: 3 }}
                        >
                            <Grid container spacing={1}>

                                <Grid xs={12}>
                                    <Typography gutterBottom variant="h5"
                                        sx={{ textAlign: 'center', backgroundColor: '#FFCC99' }}
                                    >
                                        Total enchères
                                    </Typography>
                                    <Typography component="p" variant="h4" sx={{ textAlign: 'center' }}>
                                        {values.totalAuctions}€
                                    </Typography>
                                </Grid>
                                <Grid xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid xs={12}>
                                    <Typography gutterBottom variant="h5"
                                        sx={{ textAlign: 'center', backgroundColor: '#FFCC99' }}
                                    >
                                        Total frais
                                    </Typography>
                                    <Typography component="p" variant="h4" sx={{ textAlign: 'center' }}>
                                        {values.totalFees}€
                                    </Typography>
                                </Grid>
                                <Grid xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid xs={12}>
                                    <Typography gutterBottom variant="h5"
                                        sx={{ textAlign: 'center', backgroundColor: '#FFCC99' }}
                                    >
                                        Coût total avant impôts
                                    </Typography>
                                    <Typography component="p" variant="h4"
                                        sx={{ textAlign: 'center' }}>
                                        {values.totalCostWithoutTax}€
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid xs={12}>
                    <Button variant="contained" onClick={(e) => handleSubmit(e)}
                        //TODO expand button and round it a bit
                        sx={{ width: '100%', height: '100%', borderRadius: '10px' }}
                    >Submit</Button>
                </Grid>
                <Grid xs={12}>
                    <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                            rows={values.tableRows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            sx={{
                                boxShadow: 2,
                                border: 2,
                                borderColor: 'primary.light',
                                '& .MuiDataGrid-cell:hover': {
                                    color: 'primary.main',
                                },
                                backgroundColor: '#FFFFEE',
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </Box >
    );
};